<template>
	<div class="container-card-dashboard card mb-0">
		<div class="header p-2">
			<div class="d-flex align-items-center mb-2">
				<h1>Posturas creadas:</h1>
				<p class="text">{{ totalPosturas ? totalPosturas : "---" }}</p>
			</div>
			<div>
				<b-row class="mb-1">
					<b-col cols="12" lg="4">
						<b class="mr-1">Pendientes:</b>
						<div class="d-inline-flex gap-x-1">
							<div v-for="(count, idx) in totals.pend" :key="idx">
								<span class="yearBlock" :style="{ background: getYearBlockColor(idx) }"></span>
								<span>{{ count }}</span>
							</div>
						</div>
					</b-col>
					<b-col cols="12" lg="4">
						<b class="mr-1">Activas:</b>
						<div class="d-inline-flex gap-x-1">
							<div v-for="(count, idx) in totals.act" :key="idx">
								<span class="yearBlock" :style="{ background: getYearBlockColor(idx) }"></span>
								<span>{{ count }}</span>
							</div>
						</div>
					</b-col>
					<b-col cols="12" lg="4">
						<b class="mr-1">Por finalizar:</b>
						<div class="d-inline-flex gap-x-1">
							<div v-for="(count, idx) in totals.pfin" :key="idx">
								<span class="yearBlock" :style="{ background: getYearBlockColor(idx) }"></span>
								<span>{{ count }}</span>
							</div>
						</div>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12" lg="4">
						<b class="mr-1">Finalizadas:</b>
						<div class="d-inline-flex gap-x-1">
							<div v-for="(count, idx) in totals.fin" :key="idx">
								<span class="yearBlock" :style="{ background: getYearBlockColor(idx) }"></span>
								<span>{{ count }}</span>
							</div>
						</div>
					</b-col>
					<b-col cols="12" lg="4">
						<b class="mr-1">Anuladas:</b>
						<div class="d-inline-flex gap-x-1">
							<div v-for="(count, idx) in totals.anu" :key="idx">
								<span class="yearBlock" :style="{ background: getYearBlockColor(idx) }"></span>
								<span>{{ count }}</span>
							</div>
						</div>
					</b-col>
				</b-row>
			</div>
		</div>
		<div class="body p-2">
			<div :key="keyChart" id="chart-posturas">
				<apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
			</div>
		</div>
	</div>
</template>

<script>
import encasteDashboardService from "@/services/encaste/encasteReports.service"
import VueApexCharts from "vue-apexcharts"
import moment from "moment"

export default {
	name: "DashboardPosturasByStatus",
	components: {
		apexchart: VueApexCharts,
	},
	props: {
		start_date: {
			type: String,
		},
		end_date: {
			type: String,
		},
	},
	data() {
		return {
			series: [
				{
					name: "",
					data: [],
				},
			],
			chartOptions: {},
			totalPosturas: 0,
			keyChart: 0,
			totals: { pend: [0, 0, 0], act: [0, 0, 0], pfin: [0, 0, 0], fin: [0, 0, 0], anu: [0, 0, 0] },
		}
	},
	async mounted() {
		await this.initChart()
	},
	computed: {
		sumEggs: () => (items) => {
			return items.reduce(function (previousValue, currentValue) {
				return previousValue + currentValue
			}, 0)
		},
	},
	methods: {
		async initChart() {
			const { range, status } = await encasteDashboardService.getPosturasByStatus({
				start_date: this.start_date,
				end_date: this.end_date,
			})
			const curYear = new Date(this.start_date).getFullYear()

			// first 12 months, from march to next feb
			const y1 = new Array(12)
			range.slice(0, 12).forEach((m, idx) => {
				y1[idx] = m.count
			})
			const y2 = new Array(12)
			range.slice(12, 24).forEach((m, idx) => {
				y2[idx] = m.count
			})
			const y3 = new Array(12)
			range.slice(24).forEach((m, idx) => {
				y3[idx] = m.count
			})

			const xCategories = [
				...new Set(
					range.map((item) =>
						moment(`${item.month}-01`).locale("es").format("MMMM").toLocaleUpperCase().slice(0, 3)
					)
				),
			]

			this.chartOptions = {
				chart: {
					type: "bar",
					height: 350,
					toolbar: { show: false },
				},
				plotOptions: {
					bar: { dataLabels: { position: "top" }, borderRadius: 6, borderRadiusApplication: "end" },
				},
				colors: ["#b5b5b5", "#404040", "#6636ef"],
				dataLabels: {
					enabled: true,
					formatter: (v) => v,
					offsetY: -20,
					style: { fontSize: "10px", colors: ["#000"] },
				},
				legend: {
					showForSingleSeries: true,
					position: "bottom",
					horizontalAlign: "right",
					fontSize: "18px",
					fontWeight: 600,
					labels: { colors: "#82868b" },
					markers: { width: 20, height: 16, radius: 4 },
					itemMargin: { horizontal: 10 },
				},
				stroke: { show: true, width: 2, colors: ["transparent"] },
				xaxis: {
					labels: { style: { colors: "#9aa0ac", fontSize: "14px" } },
					categories: xCategories,
				},
				yaxis: {
					title: { text: "Posturas Creadas", style: { color: "#9aa0ac" } },
					labels: { style: { colors: ["#9aa0ac"] } },
				},
				fill: { opacity: 1 },
				tooltip: { y: { formatter: (v) => v } },
			}

			this.series = [
				{ name: String(curYear - 2), data: y1 },
				{ name: String(curYear - 1), data: y2 },
				{ name: String(curYear), data: y3 },
			]

			this.totalPosturas = y3.reduce((acc, cur) => acc + Number(cur), 0)

			// quitar datos de anios que no tienen season correspondiente
			const existentSeasons = this.seasons.map((s) => s.season)

			this.series = this.series.filter((s) => existentSeasons.includes(s.name))
			// mantener color principal en ultimo elemento de array
			while (this.series.length != this.chartOptions.colors.length) {
				this.chartOptions.colors.shift()
			}
			this.setTotals(status, this.series)

			this.keyChart = this.keyChart + 1
		},
		setTotals(data, finalSeries) {
			const keysDict = new Map()
			keysDict.set("pend", "pendientes")
			keysDict.set("act", "activas")
			keysDict.set("pfin", "por_finalizar")
			keysDict.set("fin", "finalizadas")
			keysDict.set("anu", "anuladas")
			for (const status in this.totals) {
				this.totals[status] = data.map((y) => y[keysDict.get(status)])
				while (this.totals[status].length < 3) {
					this.totals[status].unshift(0)
				}
			}
			// delete data from eliminated years
			for (const status in this.totals) {
				while (this.totals[status].length != finalSeries.length) {
					this.totals[status].shift()
				}
			}
		},
		getYearBlockColor(idx) {
			const maxSerie = this.series.length - 1
			switch (idx) {
				case maxSerie - 2:
					return "#b5b5b5"
				case maxSerie - 1:
					return "#404040"
				case maxSerie:
					return "#6636ef"
			}
			return ""
		},
	},
	watch: {
		async start_date() {
			await this.initChart()
		},
	},
}
</script>

<style lang="scss" scoped>
.container-card-dashboard {
	border: 2px solid #cccccc;
	border-radius: 30px;
	.header {
		border-bottom: 2px solid #cccccc;
		display: flex;
		flex-direction: column;
		// align-items: center;
		h1 {
			color: #6d6d6d;
			font-weight: 400;
			margin-bottom: 0;
		}
		.text {
			margin-bottom: 0;
			margin-left: 0.5rem;
			font-weight: bolder;
			font-size: 28px;
			// color: #000;
		}
	}
}

.yearBlock {
	display: inline-block;
	margin-right: 0.5rem;
	width: 1rem;
	height: 1rem;
	background: gray;
}
</style>
