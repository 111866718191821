<template>
	<div>
		<div class="card mb-1 mt-1">
			<div class="mx-2 m-2">
				<b-row>
					<b-col
						cols="12"
						md="12"
						lg="4"
						class="d-flex flex-column align-items-start justify-content-center justify-content-sm-start"
					>
						<span class="text-muted">
							Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
						</span>
						<div class="container-select-year">
							<v-select
								v-model="season"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="seasons"
								:clearable="false"
								label="season"
								:reduce="(option) => option.id"
								class="w-full"
								@input="setSeasons($event)"
							/>
						</div>
					</b-col>
					<!-- Pagination -->
					<b-col cols="12" md="12" lg="8" class="flex-nowrap">
						<div class="flex flex-wrap cross-center w-full gap-x-3 xt-search-input justify-content-end">
							<div class="xt-search-input__paginate">
								<b-pagination
									v-model="current_page"
									:total-rows="total_data"
									:per-page="perpage"
									first-number
									last-number
									prev-class="prev-item"
									next-class="next-item"
								>
									<template #prev-text>
										<feather-icon icon="ChevronLeftIcon" size="18" />
									</template>
									<template #next-text>
										<feather-icon icon="ChevronRightIcon" size="18" />
									</template>
								</b-pagination>
							</div>
							<div class="container-search-input">
								<b-form-input
									@keyup.enter="init"
									class=""
									v-model="search"
									:placeholder="category == 1 ? 'Buscar Padrillo' : 'Buscar Madrilla'"
									style="width: 200px"
								/>
								<button @click="init" class="btn btn-primary">Buscar</button>
							</div>
						</div>
					</b-col>
				</b-row>
			</div>

			<div class="table-responsive mb-0">
				<b-table-simple sticky-header class="position-relative table mb-0" :style="{ minHeight: '58vh' }">
					<b-thead>
						<b-tr>
							<b-th v-for="(title, key) in headTable" :key="title.key" v-if="title.visible">
								<div class="d-flex align-items-center justify-content-center cursor-pointer">
									<p class="mb-0 mr-1">{{ title.title }}</p>
								</div>
							</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr v-if="chickens.length > 0" v-for="item in chickens" :key="item.id">
							<b-td class="text-center">
								<SpecimenPlate
									:specimen="{
										id: item.specimen.id,
										plate: item.specimen.plate,
										alias: item.specimen.alias,
										thumb: item.specimen.thumb,
										image: item.specimen.image,
									}"
								/>
							</b-td>

							<b-td class="text-center">
								{{ item.posturas }}
							</b-td>

							<b-td class="text-center">
								{{ item.nacidos }}
							</b-td>

							<b-td class="text-center">
								<div>
									<!-- <p class="mb-0">{{ item.vivos }}</p> -->
									<div class="d-flex w-100 justify-content-center align-items-center">
										<p class="mb-0">
											CP: <span class="font-weight-bolder">{{ item.cp }}</span>
										</p>
										<p class="mx-25 mb-0">|</p>
										<p class="mb-0">
											CA: <span class="font-weight-bolder">{{ item.chicks }}</span>
										</p>
										<p class="mx-25 mb-0">|</p>
										<p class="mb-0">
											E: <span class="font-weight-bolder">{{ item.specimens }}</span>
										</p>
									</div>
								</div>
							</b-td>
						</b-tr>
						<b-tr v-if="chickens.length == 0">
							<b-td colspan="4" class="text-center">
								<p class="mb-0">Sin ejemplare encontrados</p>
							</b-td>
						</b-tr>
					</b-tbody>

					<b-tfoot> </b-tfoot>
				</b-table-simple>
			</div>
		</div>
	</div>
</template>

<script>
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";
import encasteDashboardService from "@/services/encaste/encasteReports.service";

export default {
	name: "SpecimensWithPosturas",
	props: {
		category: {
			type: Number,
			required: true,
			default: 1,
		},
		start_date: {
			type: String,
		},
		end_date: {
			type: String,
		},
	},
	components: {
		SpecimenPlate,
	},
	data() {
		return {
			headTable: [
				{ key: "padrillo", title: "Padrillo", visible: this.category == 1 ? true : false },
				{ key: "madrilla", title: "Madrilla", visible: this.category == 2 ? true : false },
				{ key: "posturas", title: "Posturas", visible: true },
				{ key: "nacidos", title: "Nacidos", visible: true },
				{ key: "hijos", title: "vivos", visible: true },
			],
			search: "",
			start_page: 0,
			to_page: 0,
			total_data: 0,
			current_page: 1,
			perpage: 100,
			chickens: [],
			years: [],
			yearSelect: new Date().getFullYear(),
			season: null,
			start_date_modal: null,
			end_date_modal: null,
		};
	},
	computed: {
		sum: () => (posturas, key) => {
			return posturas.reduce(function (previousValue, currentValue) {
				return previousValue + currentValue[key];
			}, 0);
		},
	},
	async mounted() {
		this.start_date_modal = this.start_date;
		this.end_date_modal = this.end_date;

		const { id } = this.seasons.find((s) => s.start == this.start_date);
		this.season = id;
		this.setSeasons(id);

		await this.init();
	},
	methods: {
		async init() {
			this.isPreloading();
			const {
				specimens: { data, from, last_page, to, total },
			} = await encasteDashboardService.getCountPadrillosAndMadrillasDetails({
				category: this.category,
				search: this.search,
				page: this.current_page,
				perpage: this.perpage,
				start_date: this.start_date_modal,
				end_date: this.end_date_modal,
			});
			this.chickens = data;
			this.start_page = from;
			this.to_page = to;
			this.total_data = total;
			this.isPreloading(false);
		},
		setSeasons(id) {
			const { start, end } = this.seasons.find((season) => season.id == id);
			this.start_date_modal = start;
			this.end_date_modal = end;
		},
	},
	watch: {
		async current_page() {
			await this.init();
		},
		async start_date_modal() {
			await this.init();
		},
	},
};
</script>

<style lang="scss" scoped>
.cross-center {
	flex-direction: column;
	justify-content: flex-end !important;
	align-items: flex-end !important;
	align-content: flex-end !important;
	@media screen and (min-width: 1200px) {
		// padding: 1rem;
	}

	.container-search-input {
		display: flex;
		flex-direction: column;
		width: 100%;
		// margin-top: 0.5rem;
		@media screen and (min-width: 600px) {
			flex-direction: row;
			justify-content: flex-end;
			// background: red;
			width: 100%;
		}
		@media screen and (min-width: 1200px) {
			width: 60%;
		}
		input {
			width: 100% !important;
			@media screen and (min-width: 600px) {
				// width: 50% !important;
			}
		}
		button {
			margin-top: 0.5rem;
			width: 100%;
			@media screen and (min-width: 600px) {
				margin-left: 0.5rem;
				margin-top: 0;
				width: 60%;
			}
			@media screen and (min-width: 900px) {
				margin-top: 0;
			}
		}
	}
	.xt-search-input__paginate {
		margin: 1rem 0;
		// margin-top: 0;
		ul {
			margin-bottom: 0;
		}
	}
}
.container-select-year {
	margin-top: 1rem;
	width: 100%;
	@media screen and (min-width: 600px) {
		width: 80%;
	}
	@media screen and (min-width: 1200px) {
		width: 50%;
	}
}
</style>
