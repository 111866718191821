<template>
	<div class="container-card-dashboard card mb-0">
		<div class="header p-2">
			<div class="d-flex align-items-center ">
				<h1>Fertilidad: </h1>
				<p class="text">{{ totalPosturas ? totalPosturas : "---" }}</p>
			</div>
			<div class="container-select-tabs-dashboard">
				<div class="">
					<div
						class="container-select-tabs-dashboard-item"
						:class="[category == 1 ? 'active' : '']"
						@click="setCategory(1)"
					>
						<p class="mb-0">Padrillos</p>
					</div>
					<div
						class="container-select-tabs-dashboard-item"
						:class="[category == 2 ? 'active' : '']"
						@click="setCategory(2)"
					>
						<p class="mb-0">Madrillas</p>
					</div>
					<div
						class="container-select-tabs-dashboard-item"
						:class="[category == 3 ? 'active' : '']"
						@click="setCategory(3)"
					>
						<p class="mb-0">Posturas</p>
					</div>
				</div>
				<div class="container-number-input">
					<div class="container-number-input-component">
						<p class="text-description-registered-eggs">Minimo de huevos registrados.</p>
						<vue-number-input
							:step="10"
							:min="1"
							:max="1000"
							center
							vertical
							controls
							v-model="min_eggs_registered"
						></vue-number-input>
					</div>
				</div>
			</div>
		</div>
		<div class="body p-2">
			<div :key="keyChart" id="chart-posturas">
				<apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
			</div>
		</div>
	</div>
</template>

<script>
import encasteDashboardService from "@/services/encaste/encasteReports.service";
import VueApexCharts from "vue-apexcharts";

export default {
	name: "DashboardFertilidad",
	components: {
		apexchart: VueApexCharts,
	},
	props: {
		start_date: {
			type: String,
		},
		end_date: {
			type: String,
		},
	},
	data() {
		return {
			series: [
				{
					name: "",
					data: [],
				},
			],
			chartOptions: {
				chart: {
					type: "bar",
					height: 350,
					toolbar: {
						show: false,
					},
				},
				plotOptions: {
					bar: {
						borderRadius: 10,
						dataLabels: {
							position: "top", // top, center, bottom
						},
					},
				},
				colors: ["#7367F0"],
				// colors: ["#4b4b4b", "#28c76f", "#00cfe8", "#ea5455"],
				dataLabels: {
					enabled: true,
					formatter: function (val) {
						return val + "%";
					},
					offsetY: -20,
					style: {
						fontSize: "12px",
						colors: ["#7367F0"],
					},
				},
				legend: {
					show: false,
					showForSingleSeries: true,
					customLegendItems: ["Ranking"],
					markers: {
						// fillColors: ["#00E396", "#775DD0"],
					},
					labels: {
						colors: "#9aa0ac",
					},
				},
				stroke: {
					show: true,
					width: 2,
					colors: ["transparent"],
				},
				xaxis: {
					labels: {
						rotate: -45,
					},
					categories: [],
					// tickPlacement: 'on'
					labels: {
						style: {
							colors: "#9aa0ac",
							// fontSize: "14px",
						},
					},
				},
				yaxis: {
					title: {
						text: "Ranking(%)",
						style: {
							color: "#9aa0ac",
						},
					},
					labels: {
						style: {
							colors: "#9aa0ac",
							// fontSize: "14px",
						},
					},
				},
				fill: {
					type: "gradient",
					gradient: {
						shade: "light",
						type: "horizontal",
						shadeIntensity: 0.25,
						gradientToColors: undefined,
						inverseColors: true,
						opacityFrom: 0.85,
						opacityTo: 0.85,
						stops: [50, 0, 100],
					},
				},
				tooltip: {
					y: {
						formatter: function (val) {
							return "" + val + "%";
						},
					},
				},
			},
			totalPosturas: 0,
			keyChart: 0,
			category: 1,
			min_eggs_registered: 0,
		};
	},
	async mounted() {
		await this.initChart();
	},
	computed: {
		sumEggs: () => (items) => {
			return items.reduce(function (previousValue, currentValue) {
				return previousValue + currentValue;
			}, 0);
		},
	},
	methods: {
		async initChart() {
			const { range: resp } = await encasteDashboardService.getFertilidad({
				start_date: this.start_date,
				end_date: this.end_date,
				category: this.category,
				min_eggs_registered: this.min_eggs_registered,
			});

			this.chartOptions.xaxis.categories = resp.map((item) =>
				[1, 2].includes(this.category) ? `${item.specimen.plate} ${item.specimen.alias ? '/' : ''} ${item.specimen.alias ? item.specimen.alias : ''}` : `${item.code}  .`
			);
			// this.chartOptions.xaxis.labels.rotate = -90;
			this.series[0] = {
				name: [1, 2].includes(this.category) ? "" : "Postura",
				data: resp.map((item) => parseInt(item.fertilidad)),
			};
			if (resp.length > 0) {
				this.totalPosturas =
					(this.sumEggs(resp.map((item) => parseInt(item.fertilidad))) / resp.length).toFixed(2) + "%";
			} else {
				this.totalPosturas = 0;
			}

			this.keyChart = this.keyChart + 1;
		},
		async setCategory(category) {
			this.category = category;
			await this.initChart();
		},
	},
	watch: {
		async start_date() {
			await this.initChart();
		},
		// async category() {
		// 	await this.initChart();
		// },
		async min_eggs_registered() {
			await this.initChart();
		},
	},
};
</script>

<style lang="scss" scoped>
.container-card-dashboard {
	border: 2px solid #cccccc;
	border-radius: 30px;
	.header {
		border-bottom: 2px solid #cccccc;
		display: flex;
		flex-direction: column;
		// align-items: center;
		h1 {
			color: #6d6d6d;
			font-weight: 400;
			margin-bottom: 0;
		}
		.text {
			margin-bottom: 0;
			margin-left: 0.5rem;
			font-weight: bolder;
			font-size: 28px;
			// color: #000;
		}
	}
}

.container-select-tabs-dashboard {
	// background: red;
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	// background: red !important;
	// margin: 0.5rem 0;
	margin-top: 0.5rem;
	@media screen and (min-width: 900px) {
		flex-direction: row !important;
	}
	&-item {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 1rem;
		:last-child {
			margin-right: 0;
		}
		p {
			text-align: center;
			font-size: 14px;
			padding: 0.4rem 0.5rem;
			border-radius: 4px;
			// width: 100px;
			font-weight: bolder;
			cursor: pointer;
			@media screen and (min-width: 600px) {
				padding: 0.4rem 1rem;
			}
			// border-bottom: 2px solid transparent;
			@media screen and (min-width: 900px) {
				// margin: 0 5rem;
				padding: 0.4rem 2rem;
			}
		}
	}
	.active {
		p {
			transition: 0.3s all ease-in-out;
			font-weight: bold;
			color: #7367f0;
			background: rgba(92, 84, 255, 0.4);
			// border-bottom: 2px solid #7367f0;
		}
	}
	:first-child {
		display: flex;
	}
}

.container-number-input {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-top: 0.2rem;
	@media screen and (min-width: 900px) {
		margin-top: 0;
	}
	.container-number-input-component {
		margin-top: 2rem;
		width: 120px;
		position: relative;
		@media screen and (min-width: 1400px) {
			margin-top: 0;
		}
		.text-description-registered-eggs {
			position: absolute;
			// background: red;
			top: -80%;
			right: 0;
			font-size: 10px;
			color: #7367f0;
			font-weight: bold;
			margin-bottom: 0.2rem;
			line-height: 1.2;
			text-align: right;
		}
	}
}
</style>
