<template>
	<div class="card mb-0 container-card-dashboard">
		<div class="header p-2">
			<div class="d-flex align-items-center">
				<h1>Pollos vivos:</h1>
				<p class="text">{{ sumTotal ? sumTotal : "---" }}</p>
			</div>
			<div class="container-select-tabs-dashboard">
				<div class="">
					<div
						class="container-select-tabs-dashboard-item"
						:class="[idx == selectedIdx ? 'active' : '']"
						v-for="(btn, idx) in btnSeasons"
						:key="btn.id"
						@click="tabId = idx"
					>
						<p class="mb-0">{{ btn.season }}</p>
					</div>
					<!--  -->
					<div
						class="container-select-tabs-dashboard-item"
						:class="[tabId == -1 ? 'active' : '']"
						@click="tabId = -1"
					>
						<p class="mb-0">Total</p>
					</div>
				</div>
			</div>
		</div>
		<div class="body p-2">
			<div id="chart">
				<apexchart
					:key="keyChart"
					type="donut"
					width="400"
					:options="chartOptions"
					:series="series"
				></apexchart>
				<div class="text">
					<p>{{ sumTotal }}</p>
					<span>Pollos vivos</span>
				</div>
			</div>

			<div class="container-count-chicks">
				<template v-for="(item, key) in selected">
					<div
						class="item-count"
						v-if="item.count > 0"
						:key="key"
						:style="{ background: getBg(item.color, 0.2) }"
					>
						<p class="mb-0" :style="{ color: item.color }">
							{{ (item.gender == "sin asignar" ? item.gender : `${item.gender}s`).toUpperCase() }}
							{{ item.count }}
						</p>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import encasteDashboardService from "@/services/encaste/encasteReports.service"
import VueApexCharts from "vue-apexcharts"
import hexToRgba from "hex-to-rgba"

export default {
	name: "DashboardGenderOfLiveChickens",
	components: {
		apexchart: VueApexCharts,
	},
	props: {
		start_date: {
			type: String,
		},
		end_date: {
			type: String,
		},
	},
	data() {
		return {
			keyChart: 0,
			sumTotal: 0,
			series: [],
			chartOptions: {},
			tabId: 1,
			btnSeasons: [],
			//
			years: [],
			total: {},
			selected: [],
			selectedIdx: 0,
		}
	},
	computed: {},
	mounted() {
		this.setAvailableSeasons()
		// set last year
		this.initChart()
	},
	computed: {
		sumEggs: () => (items) => {
			return items.reduce(function (previousValue, currentValue) {
				return previousValue + currentValue
			}, 0)
		},
	},
	methods: {
		async initChart() {
			let selected = this.btnSeasons.find((s) => s.id == this.tabId)
			if (selected == undefined) {
				// es acumulado
				selected = this.btnSeasons[0]
			}
			const { percentage: resp } = await encasteDashboardService.getGenderOfLiveChickens({
				start_date: selected.start,
				end_date: selected.end,
				is_accumulate: this.tabId == -1 ? 1 : 0,
			})

			//
			this.years = resp.slice(0, -1)
			this.years = this.years.map((obj) => {
				for (const year in obj) {
					return obj[year]
				}
			})
			this.total = resp[resp.length - 1].total
			this.selectedIdx = this.years.length - 1
			this.selected = this.years[this.selectedIdx]
			this.uploadChart()
			//

			this.keyChart = this.keyChart + 1
		},
		getBg(color, opacity) {
			return hexToRgba(color, opacity)
		},
		setAvailableSeasons() {
			const m = this.seasons.findIndex((s) => s.start == this.start_date)
			let btns = [this.seasons[m], this.seasons[m + 1], this.seasons[m + 2]]
			btns = btns.filter((b) => b != undefined)
			btns = btns.reverse()
			this.btnSeasons = btns
			this.tabId = this.btnSeasons[this.btnSeasons.length - 1].id
		},
		uploadChart() {
			if (this.selected == undefined) return
			this.sumTotal = this.selected
				.filter((y) => !!y.count)
				.map((y) => Number(y.count))
				.reduce((acc, cur) => acc + cur, 0)
			this.chartOptions = {
				chart: { type: "donut" },
				colors: this.selected.map((y) => y.color),
				labels: this.selected.map((y) => y.gender.toUpperCase()),
				responsive: [
					{
						breakpoint: 480,
						options: {
							chart: {
								width: 200,
							},
							legend: {
								position: "bottom",
							},
						},
					},
				],
				dataLabels: {
					formatter(val, opts) {
						return [val.toFixed(2) + "%"]
					},
				},
				fill: {
					type: "gradient",
				},
				legend: {
					show: false,
				},
				grid: {
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
			}
			this.series = this.selected.map((y) => Number(y.count))
		},
	},
	watch: {
		start_date(v) {
			this.setAvailableSeasons()
			this.initChart()
		},
		tabId(val) {
			this.selectedIdx = val
			if (val == -1) {
				this.selected = this.total
			} else {
				this.selected = this.years[this.selectedIdx]
			}
			if (this.years.length == 0) return
			this.uploadChart()
		},
	},
}
</script>

<style lang="scss" scoped>
.container-count-chicks {
	display: flex;
	// flex-direction: column;;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
	margin: 0.5rem 0;
	width: 100%;
	// background: green;
	@media screen and (min-width: 1500px) {
		// flex-direction: co;
		align-items: center;
		justify-content: center;
	}
	.item-count {
		margin-top: 1rem;
		display: flex;
		align-items: center;
		// cursor: pointer;
		justify-content: center;
		// background: rgba(203, 203, 203, 0.6);
		padding: 0.2rem 0.4rem;
		border-radius: 4px;
		width: auto;
		background: lighten(red, 60%);
		margin-right: 0.5rem;
		&:last-child {
			margin-right: 0;
		}
		@media screen and (min-width: 600px) {
			padding: 0.5rem 1rem;
			margin-top: 2rem;
		}
		p {
			color: #929292;
			font-weight: bold;
			font-size: 12px;
			text-align: center;
			// color: var(--bg-chicks-born);
		}
		.circle {
			margin: 0 0.5rem 0 0;
			// width: 20px;
			// height: 20px;
			// border-radius: 50%;
			// margin-right: 10px;
		}
	}
	&:last-child {
		// margin-top: 0;
		// background: blue;
	}
}
.container-card-dashboard {
	border: 2px solid #cccccc;
	border-radius: 30px;
	.header {
		border-bottom: 2px solid #cccccc;
		display: flex;
		flex-direction: column;
		// align-items: center;
		h1 {
			color: #6d6d6d;
			font-weight: 400;
			margin-bottom: 0;
		}
		.text {
			margin-bottom: 0;
			margin-left: 0.5rem;
			font-weight: bolder;
			font-size: 28px;
			// color: #000;
		}
	}
	.body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		align-content: center;
		justify-items: center;
		text-align: center;
		@media screen and (min-width: 1500px) {
			display: flex;
		}
	}
}
#chart {
	position: relative;
	.text {
		// background: red;
		// text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		// height: 30px;
		// background: red;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 20px;
		p {
			// margin-bottom: 0.2rem;
			margin-bottom: 0;
			font-size: 12px;
			font-weight: bold;
			list-style: circle;
			@media screen and (min-width: 600px) {
				margin-bottom: 0.2rem;
				font-size: 24px;
			}
		}
		span {
			margin-top: -6px !important;
			font-size: 12px;
			font-weight: 400;
			// background: red;
			@media screen and (min-width: 600px) {
				margin-top: 0;
				margin-bottom: 0.2rem;
				font-size: 24px;
			}
		}
	}
	:first-child {
		// background: red;
		text-align: center;
		display: flex;
		justify-content: center;
	}
}
.container-select-tabs-dashboard {
	// background: red;
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	// background: red !important;
	// margin: 0.5rem 0;
	margin-top: 0.5rem;
	@media screen and (min-width: 900px) {
		flex-direction: row !important;
	}
	&-item {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 1rem;
		:last-child {
			margin-right: 0;
		}
		p {
			text-align: center;
			font-size: 14px;
			padding: 0.4rem 0.5rem;
			border-radius: 4px;
			// width: 100px;
			font-weight: bolder;
			cursor: pointer;
			@media screen and (min-width: 600px) {
				padding: 0.4rem 0.8rem;
			}
			// border-bottom: 2px solid transparent;
			@media screen and (min-width: 900px) {
				// margin: 0 5rem;
				padding: 0.4rem 1.2rem;
			}
		}
	}
	.active {
		p {
			transition: 0.3s all ease-in-out;
			font-weight: bold;
			color: #7367f0;
			background: rgba(92, 84, 255, 0.4);
			// border-bottom: 2px solid #7367f0;
		}
	}
	:first-child {
		display: flex;
	}
}
</style>
